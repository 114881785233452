import { useQuery, type UseQueryResult } from "@tanstack/react-query";

import { DEFAULT_HEARTBEAT_INTERVAL } from "~app/features/application/constants";

import { getHeartbeat, getNativeHeartbeat, type GetHeartbeatResponse } from "./heartbeat.api";

const getQueryKey = () => ["application", "heartbeat"] as const;

const useHeartbeatQuery = <TData = GetHeartbeatResponse>(): UseQueryResult<TData, Error> =>
	useQuery<GetHeartbeatResponse, Error, TData>({
		queryFn: ({ signal }) => getHeartbeat({ signal }),
		queryKey: getQueryKey(),
		refetchInterval: DEFAULT_HEARTBEAT_INTERVAL,
		refetchIntervalInBackground: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		retry: false,
		staleTime: DEFAULT_HEARTBEAT_INTERVAL,
	});

export { useHeartbeatQuery, getNativeHeartbeat, getQueryKey as getHeartbeatQueryKey };
