/**
 * Global routes - these are routes the are direct children of /$tenant
 */

import { nprogress } from "@mantine/nprogress";
import { createRoute, lazyRouteComponent, redirect, useNavigate } from "@tanstack/react-router";
import { zodSearchValidator } from "@tanstack/router-zod-adapter";
import { t } from "i18next";
import { z } from "zod";

import { LeaderboardKey } from "~app/enums";
import { interestsSearchSchema } from "~app/features/interests/api/site/interests.schema";
import { type ProfileResponse, getProfileQueryKey } from "~app/features/profile/api";

import { applicationLayout } from "./application";

export const dashboardRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/dashboard/Page"), "Page"),
	getParentRoute: () => applicationLayout,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "/",
	staticData: {
		analyticsTitle: "Dashboard",
	},
	validateSearch: zodSearchValidator(interestsSearchSchema),
});

export const submitContentRoute = createRoute({
	beforeLoad: ({ params, context }) => {
		const profile = context.queryClient.getQueryData<ProfileResponse>(getProfileQueryKey());

		if (!profile || !profile.permissions.canSubmitContent) {
			throw redirect({
				params,
				to: dashboardRoute.to,
			});
		}
	},
	getParentRoute: () => applicationLayout,
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: submitContentRoute.useParams(),
			to: submitContentRoute.to,
		});

		return null;
	},
	path: "submit-content",
	staticData: {
		analyticsTitle: "Submit Content",
		crumb: () => t("navigation.submit-content"),
	},
});

export const submitContentIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/submit-content/Page"), "Page"),
	getParentRoute: () => submitContentRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "/",
});

export const leaderboardRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/activities/leaderboard/Page"), "Page"),
	getParentRoute: () => applicationLayout,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "leaderboard",
	staticData: {
		analyticsTitle: "Leaderboard",
		crumb: () => t("navigation.leaderboard.root"),
	},
});

const leaderboardRouteParamsSchema = z
	.object({
		leaderboardKey: z.nativeEnum(LeaderboardKey),
	})
	.passthrough();

export const leaderboardDetailRoute = createRoute({
	params: {
		parse: leaderboardRouteParamsSchema.parse,
	},
	// eslint-disable-next-line sort-keys -- we allow it here as property order is important to allow types to flow through correctly
	beforeLoad: ({ params }) => {
		if (!Object.values(LeaderboardKey).includes(params.leaderboardKey)) {
			throw redirect({
				params: {
					...params,
					leaderboardKey: LeaderboardKey.MONTHLY,
				},
				to: leaderboardDetailRoute.to,
			});
		}
	},
	getParentRoute: () => leaderboardRoute,
	loader: async ({ params, context }) => {
		context.analyticsTitle = () =>
			params.leaderboardKey === LeaderboardKey.MONTHLY ? "Monthly Leaderboard" : "Overall Leaderboard";
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: leaderboardDetailRoute.useParams(),
			to: leaderboardDetailRoute.to,
		});

		return null;
	},

	path: "$leaderboardKey",
});

export const leaderboardDetailIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/activities/leaderboard/Page.Detail"), "Page"),
	getParentRoute: () => leaderboardDetailRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "/",
});

export const settingsRoute = createRoute({
	getParentRoute: () => applicationLayout,
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: settingsRoute.useParams(),
			to: settingsRoute.to,
		});

		return null;
	},
	path: "settings",
	staticData: {
		analyticsTitle: "Settings",
		crumb: () => t("navigation.settings"),
	},
});

export const settingsIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/settings/Page"), "Page"),
	getParentRoute: () => settingsRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "/",
});

export const searchRoute = createRoute({
	getParentRoute: () => applicationLayout,
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: searchRoute.useParams(),
			to: searchRoute.to,
		});

		return null;
	},

	path: "search",
	staticData: {
		analyticsTitle: "Search",
		crumb: () => t("navigation.search"),
	},
});

export const searchIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/search/Page"), "Page"),
	getParentRoute: () => searchRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "/",
});
