import { useEffect } from "react";

export const useWindowListener = <K extends keyof WindowEventMap>(
	type: K,
	handler: (event: WindowEventMap[K]) => void,
): void => {
	useEffect(() => {
		const controller = new AbortController();

		window.addEventListener(type, handler, { signal: controller.signal });

		return () => {
			controller.abort();
		};
	}, [handler, type]);
};
