import { createRoute, redirect, Outlet } from "@tanstack/react-router";
import { t } from "i18next";

import { getHeartbeatQueryKey } from "~app/features/authentication/heartbeat/api";
import { useSignOut } from "~app/features/authentication/sign-out/hooks/use-signout";
import { getProfileBaseQueryOptions } from "~app/features/profile/api";
import { getUserLanguageBaseQueryOptions } from "~app/features/settings/api/user-language";
import { getSiteConfigBaseQueryOptions } from "~app/features/site-config";
import { useWindowListener } from "~app/hooks/use-window-listener";
import { useBroadcastChannelListener } from "~app/lib/broadcast-channel";
import { UseGainsightLogin } from "~app/lib/external/gainsight";
import { type Language } from "~app/translations/types";
import { switchLanguage } from "~app/translations/utilities";

import { type RouterContext } from "../root";

import { privacyPolicyRoute, termsAndConditionsRoute } from "./legal";
import { signInRoute } from "./public";
import { tenantRoute } from "./tenant";

const resetLegalContext = ({ context }: { context: RouterContext | undefined }) => {
	if (context) {
		context.legal.acceptingPrivacyPolicy = false;
		context.legal.acceptingTermsConditions = false;
	}
};

const shouldRedirectToLegalPage = (
	siteConfigCondition: boolean | undefined,
	profileCondition: boolean | null | undefined,
	context: RouterContext["legal"],
) =>
	siteConfigCondition === true &&
	profileCondition !== undefined &&
	profileCondition !== true &&
	!context.acceptingTermsConditions &&
	!context.acceptingPrivacyPolicy;

export const privateRoute = createRoute({
	beforeLoad: async ({ params, context, location, cause }) => {
		if (!context.auth) throw new Error("Auth context not found");

		if (!(await context.auth.isAuthenticated())) {
			throw redirect({
				params: {
					tenant: params.tenant,
				},
				search: {
					redirect: location.href,
				},
				to: signInRoute.to,
			});
		}

		// This cannot throw, as we evaluate the siteConfig in the tenant route, and would not get here if the tenant was not found
		const siteConfig = await context.queryClient.ensureQueryData(getSiteConfigBaseQueryOptions());

		// TODO: handle this throwing
		const profile = await context.queryClient.ensureQueryData(getProfileBaseQueryOptions());

		// We only want to execute this logic if it's the first time we enter the route
		if (cause === "enter") {
			UseGainsightLogin(profile.id.toString(), params.tenant);

			const savedLanguage = await context.queryClient.ensureQueryData(
				getUserLanguageBaseQueryOptions(profile.id),
			);

			if (savedLanguage) await switchLanguage(savedLanguage, profile.id);
		}

		if (shouldRedirectToLegalPage(siteConfig.termsConditions, profile.acceptedTermsConditions, context.legal)) {
			context.legal.acceptingTermsConditions = true;
			throw redirect({
				params,
				search: {
					redirect: location.href,
				},
				to: termsAndConditionsRoute.to,
			});
		} else if (shouldRedirectToLegalPage(siteConfig?.privacyPolicy, profile.acceptedPrivacyPolicy, context.legal)) {
			context.legal.acceptingPrivacyPolicy = true;
			throw redirect({
				params,
				search: {
					redirect: location.href,
				},
				to: privacyPolicyRoute.to,
			});
		}
	},
	component: () => {
		const context = privateRoute.useRouteContext();
		// eslint-disable-next-line react-hooks/rules-of-hooks -- we are technically in a component
		const { signOut } = useSignOut();

		// eslint-disable-next-line react-hooks/rules-of-hooks -- we are technically in a component
		useBroadcastChannelListener("sign-out", async () => {
			await signOut({ triggerBroadcastMessage: false });
		});

		// eslint-disable-next-line react-hooks/rules-of-hooks -- we are technically in a component
		useWindowListener("focus", async () => {
			await context.queryClient.invalidateQueries({
				queryKey: getHeartbeatQueryKey(),
				refetchType: "all",
				type: "all",
			});
		});

		return <Outlet />;
	},
	getParentRoute: () => tenantRoute,
	id: "private",
	onEnter: resetLegalContext,
	onLeave: async ({ context }) => {
		resetLegalContext({ context });

		const siteConfig = await context.queryClient.ensureQueryData(getSiteConfigBaseQueryOptions());
		await switchLanguage(siteConfig.language.default as Language);
	},
	onStay: resetLegalContext,
	staticData: {
		crumb: () => t("navigation.home"),
	},
});
